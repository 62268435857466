import AudioPauseIcon from '!svg-react-loader!images/igniter/svgs/audio-pause.svg'
import AudioPlayIcon from '!svg-react-loader!images/igniter/svgs/audio-play.svg'
import CollectionIcon from '!svg-react-loader!images/igniter/svgs/collection.svg'
import DownloadIcon from '!svg-react-loader!images/igniter/svgs/download.svg'

import * as React from 'react'
import { Cell, Grid } from 'react-foundation'

import { IProps } from '../ProductProps'

import { ProductThumbnail } from '../components/ProductThumbnail'
import { AudioPlayer } from './AudioProduct/AudioPlayer'
import { useAudioControls } from './AudioProduct/hooks/use_audio_controls'
import { Waveform } from './AudioProduct/Waveform'

import { Duration } from 'luxon'
import { extractTagSlug, mergeProps } from '../utils'

const Routes: any = (window as any).Routes

const DEFAULT_PROPS: Partial<IProps> = {
  thumbnailType: 'mini',
}

const AudioProduct: React.FunctionComponent<IProps> = (props) => {
  const { product } = props
  const {
    id: productId,
    name,
    collection,
    media_type: mediaType,
    has_collections: hasCollections,
    duration,
    multiscreen,
    link,
    site_nav_parent_tag: siteNavParentTag,
    new_tag_visible: newTagVisible,
    video_url: videoUrl,
    stock_music_artist: stockMusicArtist,
    stock_music_has_stems: hasStems,
    namespaced_product_tags: namespacedProductTags,
  } = product

  const navTagName = extractTagSlug({ tag: siteNavParentTag })

  const {
    thumbnailType,
  } = mergeProps(DEFAULT_PROPS, props)

  const productClasses = [
    'product',
    'product-container',
    newTagVisible ? 'is-new' : '',
    'one-column-grid',
    navTagName,
    multiscreen ? 'multiscreen' : 'singlescreen',
    mediaType,
  ]

  const thumbnailURL = product[`${thumbnailType}_image_url`]
  const durationString = Duration.fromMillis((duration || 0) * 1000).toFormat(
    'm:ss',
  )

  const productTags = namespacedProductTags.map((tag: string) => {
    const parts = tag.split(':')
    const tagType = parts[0]
    const tagName = parts.slice(2, parts.length).join(':')

    return (tagType === 'genre_stock_music') ? tagName : null
  }).filter((t) => t != null)

  const collectionsLink = hasCollections
    ? Routes.igniter_product_collections_product_path({
        id: productId,
      })
    : null

  const handleCollectionLink = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      window.location.href = collectionsLink
    },
    [collectionsLink],
  )

  const actions = (
    <div className="actions-div">
      {hasStems ? (
        <span className="stems-actions show-for-xlarge">
          <a href={link}>Stems</a>
        </span>
      ) : null}
      {hasCollections &&
      !window.location.href.includes('collections') &&
      thumbnailType !== 'poster' ? (
        <div className="collections-actions">
          <div className="collection-link">
            <button
              onClick={handleCollectionLink}
              className="action collection-link"
            >
              <CollectionIcon />
            </button>
          </div>
        </div>
      ) : null}

      {!collection ? (
        <div className="product-actions">
          <div className="download-link">
            <button
              className="action product-download"
              data-id={productId}
              onClick={crashdown.setUpProductDownload}
            >
              <DownloadIcon />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )

  const controls = useAudioControls()

  return (
    <Cell className={`grid-y ${productClasses.join(' ')}`} data-playing={controls.playing}>
      <Grid className="align-middle">
        <Cell large={36} small={54} className="xlarge-24 smedium-32">
          <Grid className="align-middle" style={{ columnGap: '1.2rem' }}>
            <Cell shrink="all">
              <a
                href="#"
                title={name}
                className="product-link"
                onClick={(e) => { e.preventDefault(); controls.toggle() }}
              >
                <ProductThumbnail
                  url={thumbnailURL}
                  altText={name}
                  duration={duration}
                />
                {controls.playing ? <AudioPauseIcon /> : <AudioPlayIcon />}
              </a>
            </Cell>
            <Cell auto="all">
              <div className="product-info audio">
                {newTagVisible ? (
                  <div className="new-product-tag">NEW</div>
                ) : null}
                <div className="title" title={name}>
                  <a href={link}>{name}</a>
                </div>
                <div className="subtitle" title={stockMusicArtist.name}>
                  {stockMusicArtist.name}
                </div>
              </div>
            </Cell>
          </Grid>
        </Cell>
        <Cell shrink="all" className="xlarge-18 show-for-xlarge">
          <AudioPlayer
            src={videoUrl}
            controls={controls}
            id={productId}
          />
          <Waveform
            productId={productId}
            controls={controls}
          />
        </Cell>
        <Cell className="xlarge-2 smedium-6 show-for-smedium">{durationString}</Cell>
        <Cell large={12} className="xlarge-9 smedium-16 show-for-smedium text-center">
          {productTags.join(',')}
        </Cell>
        <Cell small={6} shrink="small" className="xlarge-7 text-right">{actions}</Cell>
      </Grid>
    </Cell>
  )
}

export { AudioProduct }
