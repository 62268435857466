import { IProps } from './ProductProps'

export const extractTagSlug = ({ tag }: { tag?: Partial<IProductTag> }): string => {
  return tag && tag.name
    ? tag.name.toLowerCase().replace(/\W+/, '-')
    : ''
}

export const mergeProps = (...props: Array<Partial<IProps>>) => {
  return props.reduce((previous, next) => ({
    ...previous,
    ...Object.assign(
      {},
      ...Object.entries(next || {})
        .filter(([_, v]) => v !== undefined && v !== null)
        .map(([k, v]) => ({ [k]: v })),
    ),
  }))
}
